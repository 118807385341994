/* @flow */

import * as React from 'react'
import { ProductTemplate } from '../templates'

type Props = {
  location: {
    href: string,
    state: Object,
  },
}

export default function Form(props: Props) {
  const { location } = props
  return (
    <ProductTemplate
      url={location.href}
      media="illu_speciality-wineshop.png"
      title="Découvrez la caisse enregistreuse pour caviste professionnel"
      name="Caviste professionnel"
      label="Caviste professionnel"
      description="Le métier de caviste professionnel est exigeant. Avec la caisse enregistreuse Wino, maîtrisez vos stocks et vos nombreux canaux de vente (boutique, professionnels, e-commerce). Simplifiez votre quotidien et développez votre activité."
      body={[
        {
          name: 'features',
          items: [
            {
              media: 'icon_speciality-wineshop_catalog.png',
              title: 'Catalogue produits caviste',
              content:
                'Travaillez avec un catalogue produits centralisé et optimisé pour vos activités',
            },
            {
              media: 'icon_speciality-wineshop_stocks.png',
              title: 'Gestion des stocks',
              content:
                "Contrôlez les stocks et mouvements de vos bouteilles et produits d'épicerie",
            },
            {
              media: 'icon_speciality-wineshop_prosales.png',
              title: 'Vente aux professionnels',
              content:
                'Bénéficiez d’une solution de facturation intégrée à la caisse enregistreuse',
            },
            {
              media: 'icon_speciality-wineshop_ecommerce.png',
              title: 'Boutique en ligne',
              content:
                'Connectez votre site e-commerce avec Wino pour développer vos ventes en ligne',
            },
          ],
        },
        {
          name: 'calloutCards',
          items: [
            {
              decoration: 'mujeres',
              media: 'screenshot_speciality-wineshop_catalog.png',
              alt: 'Catalogue produits caviste',
              label: 'Catalogue produits caviste',
              title: 'Un catalogue produits adapté aux besoins du caviste professionnel',
              content:
                "Avec la caisse enregistreuse Wino, bénéficiez d’un catalogue produits structuré et centralisé pour maîtriser vos ventes. Créez vos nouvelles références grâce à nos fiches produits adaptées pour la bière, le vin, les spiritueux et produits d’épicerie. Un nouveau vin à ajouter ? Sélectionnez l'appellation et la région dans une liste déroulante, renseignez le millésime et le producteur. Saisissez vos prix de vente et le tour est joué.",
            },
            {
              decoration: 'gypsyeyes',
              media: 'screenshot_speciality-wineshop_stockmanagement.png',
              alt: 'Gestion des stocks',
              label: 'Gestion des stocks',
              title: 'Un logiciel de gestion de stock pour caviste',
              content:
                'Gardez la main sur vos stocks de bouteilles depuis la caisse enregistreuse caviste. Saisissez vos entrées de stock en créant des commande fournisseurs. Suivez vos sorties de stock quels que soient vos canaux de vente et lieux de stockage. Réalisez vos inventaires sans difficulté chaque année. Enfin, suivez vos rotations de stock pour prendre les bonnes décisions.',
            },
          ],
        },
        {
          name: 'winobox',
          title: "Besoin d'un devis personnalisé pour la caisse enregistreuse Wino ?",
          media: 'photo_askquotation.png',
          content: "Toute l'équipe sera ravie de vous accompagner dans les projets de votre cave.",
          buttonText: 'Demander un devis',
          buttonLink: '/devis',
        },
        {
          name: 'calloutCards',
          items: [
            {
              decoration: 'quarantine',
              media: 'screenshot_speciality-wineshop_prosales.png',
              alt: 'Vente aux professionnels',
              label: 'Vente aux professionnels',
              title: 'Une caisse enregistreuse pour caviste adaptée à la vente aux professionnels',
              content:
                "Notre caisse enregistreuse des cavistes professionnels offre une solution de facturation aux entreprises (caves à vin, restaurants, comités d'entreprise etc.). Créez des devis, des factures et des bons de livraison depuis la caisse enregistreuse sur iPad. Envoyez des liens de paiement aux clients, et suivez vos règlements. Développez votre activité de vente aux professionnels sereinement.",
            },
            {
              decoration: 'alwadood',
              media: 'screenshot_speciality-wineshop_ecommerce.png',
              alt: 'Boutique en ligne',
              label: 'Boutique en ligne',
              title: 'Connectez votre boutique en ligne à la caisse enregistreuse caviste',
              content: `Gérez simplement la vente de vos vins en ligne grâce à nos connecteurs e-commerce entre la caisse enregistreuse caviste et votre site en ligne Shopify, Prestashop ou Woocommerce. Le catalogue Wino est importé sur votre site e-commerce et vos stocks en ligne sont à jour après chaque mouvement de stock sur vos différents canaux de vente. Gagnez du temps pour la gestion de votre site en ligne et limitez les erreurs de stock.`,
            },
          ],
        },
      ]}
    />
  )
}
